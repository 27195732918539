import React from "react";
import { Button, Text, Flex, VStack, Divider, HStack } from "@chakra-ui/react";
import UserInfoModal from "./UserInfoModal";

const UserCard = ({
  user,
  openUserModal,
  setUserToDeleteId,
  toggleUserActiveStatus,
  isUserInfoModalOpen,
  setIsUserInfoModalOpen,
  userInfo,
  setUserInfo,
}) => {
  const handleOpenModal = () => {
    setUserInfo(user);

    setIsUserInfoModalOpen(true);
  };

  return (
    <React.Fragment>
      <li key={user.id}>
        <Flex
          direction={["column", "row"]}
          alignItems={["flex-start", "center"]}
          justifyContent="space-between"
          bg="compBg"
          color="text"
          p={3}
          gap="0.4rem"
          borderRadius="md"
          cursor="pointer">
          <VStack
            alignItems={["flex-start"]}
            spacing={1}>
            {user?.firstName && user.lastName ? (
              <HStack spacing={2}>
                <Text
                  fontWeight="bold"
                  hover={"underline"}
                  onClick={handleOpenModal}>
                  {user?.firstName} {user?.lastName}
                </Text>
              </HStack>
            ) : (
              <Text
                fontSize="sm"
                color="red.500"
                fontWeight="bold">
                Name Required
              </Text>
            )}
            <HStack spacing={2}>
              <Text
                fontSize="sm"
                color="gray.500">
                {user.email}
              </Text>
            </HStack>
          </VStack>
          <HStack spacing={[2, 4]}>
            <Button
              onClick={() => openUserModal(user)}
              colorScheme="blue"
              size="sm">
              Edit
            </Button>
            <Button
              onClick={() => setUserToDeleteId(user.id)}
              colorScheme="red"
              size="sm">
              Delete
            </Button>
            <Button
              onClick={() => toggleUserActiveStatus(user.id, user.isActive)}
              size="sm">
              {user.isActive ? "Deactivate" : "Activate"}
            </Button>
          </HStack>
        </Flex>
        <Divider />
      </li>
      {isUserInfoModalOpen && (
        <UserInfoModal
          isOpen={isUserInfoModalOpen}
          onClose={() => setIsUserInfoModalOpen(false)}
          userInfo={userInfo}
        />
      )}
    </React.Fragment>
  );
};

export default UserCard;
