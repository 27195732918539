import React from "react";
import { Box, Button, Stack, Heading } from "@chakra-ui/react";
import RouteUpdateCard from "./RouteUpdateCard";

const RouteManagement = ({
  routes,
  deleteRoute,
  updateRoute,
  setUpdatedRouteName,
  setUpdatedRouteColor,
  setUpdatedBidirectional,
  setUpdatedAbbreviation,
  setUpdatedInboundTimeToCompletion,
  setUpdatedOutboundTimeToCompletion,
  openNewRouteModal,
  updatedRouteName,
  updatedRouteColor,
  updatedBidirectional,
  updatedAbbreviation,
  updatedInboundTimeToCompletion,
  updatedOutboundTimeToCompletion,
  updateId,
  setUpdateId,
  setIsStopOrderModalOpen,
}) => {
  return (
    <Box color="text">
      <Stack
        spacing={[4, 8]}
        p={[2, 4]}
        boxShadow="md"
        borderRadius="md"
        bg="compBg"
        color="text">
        <Heading
          as="h3"
          size="md"
          mb={4}>
          Manage Routes
        </Heading>
        <Button
          colorScheme="blue"
          onClick={openNewRouteModal}>
          New Route
        </Button>

        <Box>
          <ul style={{ listStyle: "none", padding: 0 }}>
            {routes.map((route) => (
              <li key={route.id}>
                <RouteUpdateCard
                  route={route}
                  deleteRoute={deleteRoute}
                  updateRoute={updateRoute}
                  setUpdatedRouteName={setUpdatedRouteName}
                  setUpdatedRouteColor={setUpdatedRouteColor}
                  setUpdatedBidirectional={setUpdatedBidirectional}
                  setUpdatedAbbreviation={setUpdatedAbbreviation}
                  setUpdatedInboundTimeToCompletion={
                    setUpdatedInboundTimeToCompletion
                  }
                  setUpdatedOutboundTimeToCompletion={
                    setUpdatedOutboundTimeToCompletion
                  }
                  updatedRouteName={updatedRouteName}
                  updatedRouteColor={updatedRouteColor}
                  updatedBidirectional={updatedBidirectional}
                  updatedAbbreviation={updatedAbbreviation}
                  updatedInboundTimeToCompletion={
                    updatedInboundTimeToCompletion
                  }
                  updatedOutboundTimeToCompletion={
                    updatedOutboundTimeToCompletion
                  }
                  setUpdateId={setUpdateId}
                  updateId={updateId}
                  setIsStopOrderModalOpen={setIsStopOrderModalOpen}
                />
              </li>
            ))}
          </ul>
        </Box>
      </Stack>
    </Box>
  );
};

export default RouteManagement;
