import React from "react";
import { Flex, Box, Text, Image, IconButton } from "@chakra-ui/react";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";

function RouteStopItem({ order, stop, handleChangeOrder, total, routeStops }) {
  const currentIndex = routeStops.findIndex((item) => item.id === stop.id);

  const prevStop = currentIndex > 0 ? routeStops[currentIndex - 1] : null;
  const prevStopId = prevStop ? prevStop.id : null;

  const nextStop =
    currentIndex < routeStops.length - 1 ? routeStops[currentIndex + 1] : null;
  const nextStopId = nextStop ? nextStop.id : null;

  return (
    <Flex
      alignItems="center"
      p={2}
      border="1px solid #E2E8F0"
      borderRadius="md"
      mb={2}>
      <Box
        textAlign="center"
        flex="0 0 10%">
        {order > 1 && (
          <IconButton
            aria-label="Move up"
            icon={<FaArrowUp />}
            onClick={() =>
              handleChangeOrder(stop.stopId, order, prevStopId, order - 1)
            }
          />
        )}
        <Box
          textAlign="center"
          flex="0 0 10%"
          mb={2}
          mt={2}>
          <Text>{order}</Text>
        </Box>
        {order < total && (
          <IconButton
            aria-label="Move down"
            icon={<FaArrowDown />}
            onClick={() =>
              handleChangeOrder(stop.stopId, order, nextStopId, order + 1)
            }
          />
        )}
      </Box>
      <Box flex="0 0 30%">
        {/* <Image
          src={stop.photo}
          alt={stop.name}
          boxSize="50px"
          borderRadius="md"
        /> */}
      </Box>
      <Box flex="1">
        <Text fontWeight="bold">{stop.name}</Text>
      </Box>
    </Flex>
  );
}

export default RouteStopItem;
