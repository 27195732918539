import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Checkbox,
  Button,
  HStack,
  Text,
} from "@chakra-ui/react";

function AnnouncementModal({ isOpen, onClose, onSubmit, announcement }) {
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    homepage: false,
    training: false,
    dispatch: false,
  });

  useEffect(() => {
    if (announcement) {
      setFormData({
        title: announcement.title || "",
        content: announcement.content || "",
        homepage: announcement.homepage || false,
        training: announcement.training || false,
        dispatch: announcement.dispatch || false,
      });
    } else {
      setFormData({
        title: "",
        content: "",
        homepage: false,
        training: false,
        dispatch: false,
      });
    }
  }, [announcement]);

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]:
        name === "homepage" || name === "training" || name === "dispatch"
          ? checked
          : value,
    }));
  };

  const handleSubmit = () => {
    onSubmit(formData);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bg="compBg"
        color="text">
        <ModalHeader>
          {announcement ? "Edit Announcement" : "Create Announcement"}
        </ModalHeader>
        <ModalBody>
          <Input
            name="title"
            placeholder="Title"
            value={formData.title}
            onChange={handleInputChange}
            mb={3}
          />
          <Input
            name="content"
            placeholder="Content"
            value={formData.content}
            onChange={handleInputChange}
            mb={3}
          />
          <Text mt={2}>Show on Page:</Text>
          <HStack>
            <Checkbox
              name="homepage"
              isChecked={formData.homepage}
              onChange={handleInputChange}
              mb={3}>
              Homepage
            </Checkbox>
            <Checkbox
              name="training"
              isChecked={formData.training}
              onChange={handleInputChange}
              mb={3}>
              Training
            </Checkbox>
            <Checkbox
              name="dispatch"
              isChecked={formData.dispatch}
              onChange={handleInputChange}
              mb={3}>
              Dispatch
            </Checkbox>
          </HStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleSubmit}>
            {announcement ? "Save Changes" : "Create"}
          </Button>
          <Button
            variant="ghost"
            onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AnnouncementModal;
