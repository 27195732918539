import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Text, Stack, Button } from "@chakra-ui/react";
import AnnouncementItem from "./AnnouncementItem";
import SimpleAnnouncement from "./SimpleAnnouncement";

const RecentAnnouncements = ({ page }) => {
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    fetchRecentAnnouncements();
  }, []);

  const fetchRecentAnnouncements = async () => {
    try {
      const response = await axios.get("/api/announcements");

      // Filter announcements based on whether page variable matches the announcement's page value
      const filteredAnnouncements = response.data.filter(
        (announcement) => announcement[page]
      );

      // Sort filtered announcements by updatedAt in descending order
      const sortedAnnouncements = filteredAnnouncements
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        .slice(0, 2);

      setAnnouncements(sortedAnnouncements);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      p={4}
      boxShadow="md"
      borderRadius="md"
      bg="compBg"
      color="text">
      <Stack spacing={4}>
        <Text
          fontWeight="bold"
          fontSize="lg"
          mr="auto">
          Recent Announcements
        </Text>
        {announcements.map((announcement) => (
          <SimpleAnnouncement
            key={announcement.id}
            announcement={announcement}
          />
        ))}
        <Button
          colorScheme="blue"
          size="sm">
          View All Announcements
        </Button>
      </Stack>
    </Box>
  );
};

export default RecentAnnouncements;
