import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
} from "@chakra-ui/react";
import StopAttachment from "./StopAttachment";

function StopModal({ isOpen, onClose, addStop, newStop, setNewStop }) {
  const handleAddStop = async () => {
    try {
      await addStop(newStop);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const isNameValid = newStop.name.length >= 2;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bg="compBg"
        color="text">
        <ModalHeader>Add Stops</ModalHeader>
        <ModalBody>
          <Input
            placeholder="Stop Name"
            value={newStop.name}
            onChange={(e) => setNewStop({ ...newStop, name: e.target.value })}
            sx={{ marginBottom: 2 }}
          />
          <StopAttachment
            attachment={newStop.attachment}
            setNewStop={setNewStop}
            onRemove={() => setNewStop({ ...newStop, attachment: null })}
          />
        </ModalBody>
        <ModalFooter sx={{ gap: 2 }}>
          <Button
            colorScheme="green"
            onClick={handleAddStop}
            isDisabled={!isNameValid}>
            Add Stop
          </Button>
          <Button
            variant="outline"
            onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default StopModal;
