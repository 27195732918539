import React from "react";
import { Box, Text, Flex, Heading } from "@chakra-ui/react";

const SimpleAnnouncement = ({ announcement }) => {
  const formattedDate = new Date(announcement.createdAt).toLocaleDateString(
    "en-US",
    {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    }
  );

  return (
    <Box
      p={4}
      boxShadow="md"
      bg="compBg"
      color="text"
      marginBottom={4}
      borderLeftWidth={4}
      borderLeftColor="blue.500" // Adjust color as needed
    >
      <Heading
        as="h3"
        fontSize="xl"
        mb={2}
        align="start">
        {announcement.title}
      </Heading>
      <Text
        fontSize="md"
        mb={2}
        align="start">
        {announcement.content}
      </Text>
      <Flex
        mt={2}
        align="center"
        color="gray.500">
        <Text
          fontSize="sm"
          mr={2}>
          Posted by {announcement.user.firstName} {announcement.user.lastName}{" "}
          on {formattedDate}
        </Text>
      </Flex>
    </Box>
  );
};

export default SimpleAnnouncement;
