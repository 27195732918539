import React, { useState } from "react";
import { Accordion, Box } from "@chakra-ui/react";
import RouteAccordionItem from "./RouteAccordionItem";
import RouteDetailsModal from "../RouteDetailsModal"; // Import the modal component here

function RouteAccordion({
  routes,
  handleCreateServiceUpdate,
  handleUpdateServiceUpdate,
  handleServiceUpdateDelete,
  handleEnableDisableRoute,
  setRoutes,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);

  // Function to handle viewing route details
  const handleViewRouteDetails = (route) => {
    setSelectedRoute(route);
    setIsModalOpen(true);
  };

  return (
    <Box>
      <Accordion allowToggle>
        {routes.map((route) => (
          <RouteAccordionItem
            key={route.id}
            route={route}
            handleCreateServiceUpdate={handleCreateServiceUpdate}
            handleUpdateServiceUpdate={handleUpdateServiceUpdate}
            handleServiceUpdateDelete={handleServiceUpdateDelete}
            handleEnableDisableRoute={handleEnableDisableRoute}
            onViewRouteDetails={handleViewRouteDetails}
          />
        ))}
      </Accordion>

      <RouteDetailsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        route={selectedRoute}
      />
    </Box>
  );
}

export default RouteAccordion;
