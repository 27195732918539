import React, { useState, useEffect } from "react";
import {
  Box,
  Input,
  Text,
  Stack,
  HStack,
  Switch,
  FormControl,
  FormLabel,
  Button,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Tooltip,
} from "@chakra-ui/react";

const RouteUpdateCard = ({
  route,
  deleteRoute,
  updateRoute,
  setUpdatedRouteName,
  setUpdatedRouteColor,
  setUpdatedBidirectional,
  setUpdatedAbbreviation,
  setUpdatedInboundTimeToCompletion,
  setUpdatedOutboundTimeToCompletion,
  updateId,
  setUpdateId,
  updatedRouteName,
  updatedRouteColor,
  updatedBidirectional,
  updatedAbbreviation,
  updatedInboundTimeToCompletion,
  updatedOutboundTimeToCompletion,
  setIsStopOrderModalOpen,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  console.log(route);
  useEffect(() => {
    if (isEditing && updateId !== route.id) {
      setIsEditing(false);
    }
  }, [updateId, route.id, isEditing]);

  const handleEditClick = () => {
    setIsEditing(true);
    setUpdateId(route.id);

    setUpdatedRouteName(route.name);
    setUpdatedRouteColor(route.color);
    setUpdatedAbbreviation(route.abbreviation);
    setUpdatedInboundTimeToCompletion(route.inboundTimeToCompletion);
    setUpdatedOutboundTimeToCompletion(route.outboundTimeToCompletion);
    setUpdatedBidirectional(route.bidirectional);
  };

  const handleUpdateClick = () => {
    setIsEditing(false);
    setUpdateId(null);
    updateRoute(
      route.id,
      updatedRouteName,
      updatedRouteColor,
      updatedBidirectional,
      updatedAbbreviation,
      updatedInboundTimeToCompletion,
      updatedOutboundTimeToCompletion
    );
  };
  const handleRouteOrderClick = () => {
    setUpdateId(route.id);
    setIsStopOrderModalOpen(true);
  };
  const handleCancelClick = () => {
    setIsEditing(false);
    setUpdateId(null);

    setUpdatedRouteName(null);
    setUpdatedRouteColor(null);
    setUpdatedAbbreviation(null);
    setUpdatedInboundTimeToCompletion(null);
    setUpdatedOutboundTimeToCompletion(null);
    setUpdatedBidirectional(null);
  };

  return (
    <Stack
      bg="compBg"
      p={3}
      borderRadius="md"
      spacing={4}
      alignItems="flex-start"
      borderWidth={1}
      borderColor="gray.200"
      boxShadow="md"
      mt={2}
      mb={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        w="100%">
        <HStack
          spacing={3}
          alignItems="center">
          <Box
            position="relative"
            width="30px"
            height="30px"
            borderRadius="50%"
            bg={route.color}>
            <Text
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              color="white"
              fontWeight="bold"
              fontSize="12px">
              {route.abbreviation}
            </Text>
          </Box>
          <Text
            fontSize="lg"
            fontWeight="bold">
            {route.name}
          </Text>
        </HStack>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end">
          <FormLabel
            htmlFor={`bidirectional-toggle-${route.id}`}
            mb="0">
            Bi-Directional
          </FormLabel>
          <Switch
            id={`bidirectional-toggle-${route.id}`}
            isChecked={
              updateId !== route.id ? route.bidirectional : updatedBidirectional
            }
            onChange={() => {
              setUpdatedBidirectional((prev) => !prev);
            }}
            disabled={updateId !== route.id}
          />
        </Box>
      </Box>

      {updateId === route.id && isEditing && (
        <>
          <FormLabel>Name</FormLabel>
          <Input
            placeholder="New Name"
            size="sm"
            value={updatedRouteName}
            onChange={(e) => setUpdatedRouteName(e.target.value)}
          />
          <HStack
            sx={{ justifyContent: "flex-start" }}
            spacing={4}
            flexWrap="wrap"
            w="100%">
            <HStack spacing={2}>
              <HStack>
                {" "}
                <FormControl>
                  <FormLabel>{route.abbreviation} Hex Color</FormLabel>
                  <Input
                    placeholder="New Hex Color"
                    size="sm"
                    value={updatedRouteColor}
                    onChange={(e) => setUpdatedRouteColor(e.target.value)}
                  />
                </FormControl>
              </HStack>
              <HStack>
                {" "}
                <FormControl>
                  <FormLabel>Route Abbreviation</FormLabel>
                  <Input
                    placeholder="Abbreviation (Max 3 chars)"
                    size="sm"
                    maxLength={3}
                    value={updatedAbbreviation}
                    onChange={(e) =>
                      setUpdatedAbbreviation(
                        e.target.value.replace(/[^a-zA-Z]/g, "").toUpperCase()
                      )
                    }
                  />
                </FormControl>
              </HStack>
            </HStack>

            <>
              <HStack spacing={2}>
                <HStack>
                  <FormControl>
                    {route.bidirectional ? (
                      <FormLabel>
                        {route.abbreviation} Inbound Time To Complete
                      </FormLabel>
                    ) : (
                      <FormLabel>
                        {route.abbreviation} Time To Complete
                      </FormLabel>
                    )}
                    <NumberInput
                      size="sm"
                      value={updatedInboundTimeToCompletion || ""}
                      onChange={(valueString) =>
                        setUpdatedInboundTimeToCompletion(parseInt(valueString))
                      }
                      min={0}>
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </FormControl>
                </HStack>
                {route.bidirectional && (
                  <HStack>
                    <FormControl>
                      <FormLabel>
                        {route.abbreviation} Outbound Time To Complete
                      </FormLabel>
                      <NumberInput
                        size="sm"
                        value={updatedOutboundTimeToCompletion || ""}
                        onChange={(valueString) =>
                          setUpdatedOutboundTimeToCompletion(
                            parseInt(valueString)
                          )
                        }
                        min={0}>
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                  </HStack>
                )}
              </HStack>
            </>
          </HStack>
        </>
      )}
      <HStack
        spacing={2}
        align="end"
        justify="space-between"
        w="100%">
        {!isEditing && updateId !== route.id && (
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: "column",
            }}>
            {" "}
            {!route.bidirectional && route.inboundTimeToCompletion && (
              <Text
                fontSize="sm"
                fontWeight="600">
                Time to Complete: {route.inboundTimeToCompletion} mins
              </Text>
            )}
            {route.bidirectional && route.inboundTimeToCompletion && (
              <Text
                fontSize="sm"
                fontWeight="600">
                Outbound Time to Complete: {route.outboundTimeToCompletion} mins
              </Text>
            )}
            {route.bidirectional && route.outboundTimeToCompletion && (
              <Text
                fontSize="sm"
                fontWeight="600">
                Inbound Time to Complete: {route.inboundTimeToCompletion} mins
              </Text>
            )}
          </Box>
        )}
        <HStack
          spacing={2}
          justify="space-between">
          {!isEditing && (
            <Button
              onClick={handleRouteOrderClick}
              colorScheme="teal"
              size="sm">
              {route.abbreviation} Stop Order
            </Button>
          )}
          {!isEditing ? (
            <Button
              onClick={handleEditClick}
              colorScheme="blue"
              size="sm">
              Edit
            </Button>
          ) : (
            <>
              <Button
                onClick={handleUpdateClick}
                colorScheme="blue"
                size="sm">
                Update
              </Button>
              <Button
                onClick={handleCancelClick}
                colorScheme="gray"
                size="sm">
                Cancel
              </Button>
            </>
          )}
          <Tooltip
            label="Delete Route"
            hasArrow>
            <Button
              onClick={() => deleteRoute(route.id)}
              colorScheme="red"
              size="sm">
              Delete
            </Button>
          </Tooltip>
        </HStack>
      </HStack>
    </Stack>
  );
};

export default RouteUpdateCard;
