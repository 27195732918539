import React, { useState, useEffect } from "react";
import {
  Text,
  Stack,
  HStack,
  Button,
  Tooltip,
  Image as ChakraImage,
} from "@chakra-ui/react";

const StopUpdateCard = ({
  stop,
  deleteStop,
  updateStop,
  setUpdatedStopName,
  updatedStopName,
  updatedStopImage,
  updateId,
  setUpdateId,
  openEditStopModal,
  setUpdatedStopImage,
  setUpdatedStopRouteIds,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (isEditing && updateId !== stop.id) {
      setIsEditing(false);
    }
  }, [updateId, stop.id, isEditing]);

  const handleEditClick = () => {
    setIsEditing(true);
    setUpdateId(stop.id);
    setUpdatedStopName(stop.name);
    setUpdatedStopRouteIds(
      stop.routes ? stop.routes.map((route) => route.id) : []
    );

    openEditStopModal();
  };

  return (
    <Stack
      bg="compBg"
      p={3}
      borderRadius="md"
      spacing={4}
      alignItems="flex-start"
      borderWidth={1}
      borderColor="gray.200"
      boxShadow="md"
      mt={2}
      mb={2}>
      <HStack
        justifyContent="space-between"
        w="100%">
        {" "}
        <HStack
          mt={2}
          gap={4}>
          {stop.image ? (
            <ChakraImage
              src={stop.image.link}
              alt="Stop Image"
              maxW="80px" // Adjust size as needed
              maxH="80px"
            />
          ) : (
            <Text>No image available</Text>
          )}{" "}
          <Text
            fontSize="lg"
            fontWeight="bold">
            {stop.name}
          </Text>
        </HStack>
        <HStack>
          <Button
            onClick={handleEditClick}
            colorScheme="blue"
            size="sm">
            Edit
          </Button>
          <Tooltip
            label="Delete Stop"
            hasArrow>
            <Button
              onClick={() => deleteStop(stop.id)}
              colorScheme="red"
              size="sm">
              Delete
            </Button>
          </Tooltip>
        </HStack>
      </HStack>
    </Stack>
  );
};

export default StopUpdateCard;
