import React, { useState } from "react";
import {
  Box,
  Button,
  Stack,
  Heading,
  Divider,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import UserManagementActionsModal from "./UserManagementActionsModal";
import UserInfoModal from "./UserInfoModal";
import axios from "axios";
import UserCard from "./UserCard";
import { useSnackbar } from "../../context/SnackbarProvider";

const UserManagement = ({
  users,
  handleDeleteUser,
  openModal,
  updateUser,
  setEditUser,
  editUser,
  setUserToDeleteId,
  userToDeleteId,
  userToResetPasswordId,
  setUserToResetPasswordId,
  handleResetPassword,
  fetchUsers,
}) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [activeTab, setActiveTab] = useState("active");
  const [isUserInfoModalOpen, setIsUserInfoModalOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tierPoints, setTierPoints] = useState(null);
  const [manualTierPoints, setManualTierPoints] = useState(0);
  const { showSuccessToast, showErrorToast } = useSnackbar();
  const handleChangeTierPoints = (value) => {
    setManualTierPoints(value);
  };

  const handleSaveTierPoints = async () => {
    try {
      const { data } = await axios.put(`/auth/points/${userInfo.id}/`, {
        tierPoints: tierPoints + manualTierPoints,
      });
      fetchUsers();
      setTierPoints(tierPoints + manualTierPoints);
      setManualTierPoints(0);
      setIsUserInfoModalOpen(false);
      showSuccessToast("Tier points added successfully!"); // Show success toast
    } catch (error) {
      console.error("Network error:", error.message);
      showErrorToast("Network error!"); // Show error toast
    }
  };
  const openUserModal = (user) => {
    setSelectedUser(user);
    setEditUser({
      firstName: user.firstName,
      lastName: user.lastName,
      photoURL: user.photoURL,
      isAdmin: user.isAdmin,
      permissions: user.permissions,
      telephone: user.telephone,
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    if (userToDeleteId) {
      setUserToDeleteId(null);
    } else if (userToResetPasswordId) {
      setUserToResetPasswordId(null);
    } else {
      setSelectedUser(null);
      setEditUser(null);
      setSelectedFile(null);
      setIsModalOpen(false);
    }
  };

  const handlePermissionChange = (tabName, variable) => {
    setEditUser((prevEditUser) => {
      const updatedPermissions = prevEditUser.permissions.map((permission) => {
        if (permission.tabName === tabName) {
          return {
            ...permission,
            [variable]: !permission[variable],
          };
        }
        return permission;
      });

      return {
        ...prevEditUser,
        permissions: updatedPermissions,
      };
    });
  };
  const handleSaveChanges = async () => {
    try {
      await updateUser(selectedUser.id, editUser, selectedFile);
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const toggleUserActiveStatus = async (userId, isActive) => {
    try {
      await updateUser(userId, { isActive: !isActive });
    } catch (error) {
      console.log(error);
    }
  };

  const openUserInfoModal = async (userId) => {
    try {
      const response = await axios.get(`/user/${userId}`);

      setUserInfo(response.data);
      setIsUserInfoModalOpen(true);
    } catch (error) {
      console.error("Network error:", error.message);
    }
  };

  return (
    <Stack
      spacing={[4, 8]}
      p={[2, 4]}
      boxShadow="md"
      borderRadius="md"
      bg="compBg"
      color="text">
      <Heading
        as="h3"
        size="md"
        mb={4}>
        Manage Users
      </Heading>
      <Button
        colorScheme="blue"
        onClick={() => openModal("inviteUser")}>
        New User
      </Button>

      <Tabs
        isFitted
        variant="enclosed">
        <TabList>
          <Tab
            onClick={() => setActiveTab("active")}
            isSelected={activeTab === "active"}>
            Active
          </Tab>
          <Tab
            onClick={() => setActiveTab("inactive")}
            isSelected={activeTab === "inactive"}>
            Inactive
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box>
              <Divider />
              <ul style={{ listStyle: "none", padding: 0 }}>
                {users
                  .filter((user) => user.isActive)
                  .map((user) => (
                    <UserCard
                      key={user.id}
                      user={user}
                      setIsUserInfoModalOpen={setIsUserInfoModalOpen}
                      setUserToDeleteId={setUserToDeleteId}
                      toggleUserActiveStatus={toggleUserActiveStatus}
                      openUserInfoModal={openUserInfoModal}
                      isUserInfoModalOpen={isUserInfoModalOpen}
                      setTierPoints={setTierPoints}
                      setManualTierPoints={setManualTierPoints}
                      userInfo={userInfo}
                      setUserInfo={setUserInfo}
                      openUserModal={openUserModal}
                    />
                  ))}
              </ul>
            </Box>
          </TabPanel>
          <TabPanel>
            <Box>
              <Divider />
              <ul style={{ listStyle: "none", padding: 0 }}>
                {users
                  .filter((user) => !user.isActive)
                  .map((user) => (
                    <UserCard
                      key={user.id}
                      user={user}
                      setIsUserInfoModalOpen={setIsUserInfoModalOpen}
                      setUserToDeleteId={setUserToDeleteId}
                      toggleUserActiveStatus={toggleUserActiveStatus}
                      openUserInfoModal={openUserInfoModal}
                      isUserInfoModalOpen={isUserInfoModalOpen}
                      setTierPoints={setTierPoints}
                      setManualTierPoints={setManualTierPoints}
                      userInfo={userInfo}
                      setUserInfo={setUserInfo}
                      openUserModal={openUserModal}
                    />
                  ))}
              </ul>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <UserManagementActionsModal
        isOpen={selectedUser || userToDeleteId || userToResetPasswordId}
        userToDeleteId={userToDeleteId}
        handleDeleteUser={handleDeleteUser}
        closeModal={closeModal}
        selectedUser={selectedUser}
        selectedFile={selectedFile}
        editUser={editUser}
        handleFileChange={handleFileChange}
        setEditUser={setEditUser}
        handlePermissionChange={handlePermissionChange}
        handleSaveChanges={handleSaveChanges}
        userToResetPasswordId={userToResetPasswordId}
        setUserToResetPasswordId={setUserToResetPasswordId}
        handleResetPassword={handleResetPassword}
      />

      {isUserInfoModalOpen && (
        <UserInfoModal
          isOpen={isUserInfoModalOpen}
          onClose={() => setIsUserInfoModalOpen(false)}
          user={userInfo}
          handleChangeTierPoints={handleChangeTierPoints}
          handleSaveTierPoints={handleSaveTierPoints}
        />
      )}
    </Stack>
  );
};

export default UserManagement;
