import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
  VStack,
  FormControl,
  FormErrorMessage,
  Checkbox,
} from "@chakra-ui/react";

function InviteUserModal({
  isOpen,

  handleInviteUser,
  newUser,
  setNewUser,

  onClose,
  isValidEmailFormat,
  isValidNameFormat,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        color="text"
        bg="compBg">
        <ModalHeader>Invite New User</ModalHeader>
        <ModalBody>
          <VStack
            spacing={4}
            align="stretch">
            <FormControl isInvalid={!isValidEmailFormat}>
              <Input
                placeholder="Email"
                value={newUser.email}
                onChange={(e) =>
                  setNewUser({ ...newUser, email: e.target.value })
                }
              />
              <FormErrorMessage>
                Email is not in a valid format.
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!isValidNameFormat}>
              <Input
                placeholder="Name"
                value={newUser.name}
                onChange={(e) =>
                  setNewUser({ ...newUser, name: e.target.value })
                }
              />
              <FormErrorMessage>
                Name can only contain alphanumeric characters and spaces.
              </FormErrorMessage>
            </FormControl>
            <Checkbox
              value={newUser.isAdmin}
              onChange={(e) =>
                setNewUser({ ...newUser, isAdmin: e.target.value })
              }>
              Admin
            </Checkbox>
          </VStack>
        </ModalBody>
        <ModalFooter sx={{ display: "flex", gap: "0.8rem" }}>
          <Button
            colorScheme="green"
            onClick={handleInviteUser}
            isDisabled={!isValidEmailFormat}>
            Invite User
          </Button>
          <Button
            color="text"
            bg="compBg"
            variant="outline"
            onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default InviteUserModal;
