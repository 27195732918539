import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { MinusIcon } from "@chakra-ui/icons";
import FileUpload from "../ResourceManagement/FileUpload";

const StopAttachment = ({
  attachment,
  onRemove,
  setNewStop = null,
  setAttachment = null,
}) => {
  const [attachmentData, setAttachmentData] = useState({
    type: attachment?.type || "",
    file: null,
    imageUrl: "",
  });

  const handleFileChange = (file) => {
    setAttachmentData((prev) => ({
      ...prev,
      file: file,
      type: file.type.includes("image") ? "image" : "pdf",
      imageUrl: URL.createObjectURL(file),
    }));

    if (setNewStop) {
      setNewStop((prev) => {
        return {
          ...prev,
          attachment: {
            ...prev.attachment,
            file: file,
            type: file.type.includes("image") ? "image" : "pdf",
            imageUrl: URL.createObjectURL(file),
          },
        };
      });
    } else {
      setAttachment(() => {
        return {
          file: file,
          type: file.type.includes("image") ? "image" : "pdf",
          imageUrl: URL.createObjectURL(file),
        };
      });
    }
  };

  const handleRemove = () => {
    setAttachmentData({
      type: "",
      file: null,
      imageUrl: "",
    });
    onRemove();
  };

  return (
    <Stack
      direction="column"
      spacing={4}
      align="stretch"
      m="1.6rem 0"
      p={4}
      borderRadius="md"
      boxShadow="md">
      <FormLabel
        mb={0}
        fontSize="sm">
        Stop Image
      </FormLabel>
      {attachmentData.file && attachmentData.type === "image" ? (
        <HStack
          alignItems="center"
          justifyContent="">
          <Image
            src={attachmentData.imageUrl}
            alt="Uploaded Image"
            boxSize="45px"
            objectFit="cover"
            borderRadius="md"
          />
          <IconButton
            colorScheme="red"
            size="sm"
            aria-label="Remove Attachment"
            icon={<MinusIcon />}
            onClick={handleRemove}
          />
        </HStack>
      ) : (
        <FormControl>
          <FileUpload onUpload={handleFileChange} />
        </FormControl>
      )}
      {attachmentData.file && attachmentData.type !== "image" && (
        <Text>
          File Type: {attachmentData.type}, Size: {attachmentData.file.size}{" "}
          bytes
        </Text>
      )}
    </Stack>
  );
};

export default StopAttachment;
