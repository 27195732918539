import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Box,
} from "@chakra-ui/react";
import RouteStopItem from "./RouteStopItem"; // Import the RouteStopItem component

function RouteStopOrderModal({
  isOpen,
  onClose,
  routes,
  updateId,
  handleSwapStopPosition,
}) {
  const route = routes.find((route) => route.id === updateId);

  const stopIds = route ? route.stopIds : [];

  const sortedStopIds = [...stopIds].sort((a, b) => a.order - b.order);

  const handleChangeOrder = async (stopId1, order1, stopId2, order2) => {
    await handleSwapStopPosition(stopId1, order1, stopId2, order2);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg">
      <ModalOverlay />
      <ModalContent
        bg="compBg"
        color="text">
        <ModalHeader>Change RouteStop Order</ModalHeader>
        <ModalBody
          maxHeight="560px"
          overflowY="auto">
          {sortedStopIds.map((stop, index) => (
            <RouteStopItem
              key={stop.id + index * 100}
              order={stop.order}
              stop={stop}
              handleChangeOrder={handleChangeOrder}
              total={sortedStopIds.length}
              routeStops={sortedStopIds}
            />
          ))}
          <Box h="200px" />
        </ModalBody>
        <ModalFooter sx={{ gap: 2 }}>
          <Button
            variant="outline"
            onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default RouteStopOrderModal;
