import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
  Switch,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";

function RouteModal({ isOpen, onClose, addRoute, newRoute, setNewRoute }) {
  const handleAddRoute = async () => {
    try {
      await addRoute(newRoute);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggleBidirectional = () => {
    setNewRoute({ ...newRoute, bidirectional: !newRoute.bidirectional });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bg="compBg"
        color="text">
        <ModalHeader>Add Routes</ModalHeader>
        <ModalBody>
          <Input
            placeholder="Route Name"
            value={newRoute.name}
            onChange={(e) => setNewRoute({ ...newRoute, name: e.target.value })}
            sx={{ marginBottom: 2 }}
          />
          <Input
            placeholder="Hex Color"
            value={newRoute.hexColor}
            onChange={(e) =>
              setNewRoute({ ...newRoute, hexColor: e.target.value })
            }
          />
          <FormControl
            display="flex"
            alignItems="center"
            mt={4}>
            <FormLabel
              htmlFor="bidirectional-toggle"
              mb="0">
              Bidirectional Route:
            </FormLabel>
            <Switch
              id="bidirectional-toggle"
              isChecked={newRoute.bidirectional}
              onChange={handleToggleBidirectional}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter sx={{ gap: 2 }}>
          <Button
            colorScheme="green"
            onClick={handleAddRoute}>
            Add Route
          </Button>
          <Button
            variant="outline"
            onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default RouteModal;
