import React, { useState, useEffect } from "react";
import { Box, Button, Center, Flex, Stack } from "@chakra-ui/react";
import axios from "axios";

import { useSnackbar } from "../../context/SnackbarProvider";
import UserManagement from "../../components/UserManagement/UserManagement";
import BlocksheetManagement from "../../components/BlocksheetManagement/BlocksheetManagement";
import RouteManagement from "../../components/RouteManagement/RouteManagement";
import RouteModal from "../../components/RouteManagement/RouteModal";
import StopManagement from "../../components/StopManagement/StopManagement";
import StopModal from "../../components/StopManagement/StopModal";
import InviteUserModal from "../../components/UserManagement/InviteUserModal";
import StopEditModal from "../../components/StopManagement/StopEditModal";
import RouteStopOrderModal from "../../components/RouteManagement/RouteStopOrderModal";
import Announcements from "../../components/Announcements/Announcements";

function Admin({ socket, logout, user, changePasswordWithToken }) {
  const [activeTab, setActiveTab] = useState("user");
  const [users, setUsers] = useState([]);
  const [scheduleBlocks, setScheduleBlocks] = useState([]);
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    password: "",
    isAdmin: false,
  });
  const [editUser, setEditUser] = useState({
    isAdmin: false,
  });

  const [newBlock, setNewBlock] = useState({
    type: "",
    term: "",
    date: null,
    startTime: null,
    endTime: null,
    name: "",
    ownerId: null,
  });
  const [newRoute, setNewRoute] = useState({
    name: "",
    hexColor: "",
    bidirectional: false,
  });
  const [newStop, setNewStop] = useState({
    name: "",

    attachment: null,
    routeIds: [],
  });

  const [isRouteModalOpen, setIsRouteModalOpen] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isStopModalOpen, setIsStopModalOpen] = useState(false);
  const [isStopEditModalOpen, setIsStopEditModalOpen] = useState(false);
  const [isBlocksheetModalOpen, setIsBlocksheetModalOpen] = useState(false);
  const [isStopOrderModalOpen, setIsStopOrderModalOpen] = useState(false);

  const [isValidEmailFormat, setIsValidEmailFormat] = useState(false);
  const [isValidNameFormat, setIsValidNameFormat] = useState(true);
  const [userToDeleteId, setUserToDeleteId] = useState(null);
  const [userToResetPasswordId, setUserToResetPasswordId] = useState(null);
  const [blockToDeleteId, setBlockToDeleteId] = useState(null);

  const [currentWeek, setCurrentWeek] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [blockSheetUsers, setBlockSheetUsers] = useState([]);
  const [editBlock, setEditBlock] = useState(null);

  const [updatedRouteName, setUpdatedRouteName] = useState("");
  const [updatedRouteColor, setUpdatedRouteColor] = useState("");
  const [updatedBidirectional, setUpdatedBidirectional] = useState(null);
  const [updatedAbbreviation, setUpdatedAbbreviation] = useState("");
  const [updatedInboundTimeToCompletion, setUpdatedInboundTimeToCompletion] =
    useState("");
  const [updatedOutboundTimeToCompletion, setUpdatedOutboundTimeToCompletion] =
    useState("");
  const [updateId, setUpdateId] = useState(null);
  const [routes, setRoutes] = useState([]);
  const [stops, setStops] = useState([]);

  const [updatedStopName, setUpdatedStopName] = useState("");
  const [updatedStopImage, setUpdatedStopImage] = useState(null);
  const [updatedStopRouteIds, setUpdatedStopRouteIds] = useState([]);
  const [selectedRouteStopsOrder, setSelectedRouteStopsOrder] = useState([]);

  const { showSuccessToast, showErrorToast } = useSnackbar();
  const handleWeekChange = (change) => {
    setCurrentWeek(currentWeek + change);
  };
  useEffect(() => {
    fetchRoutes();
    fetchUsers();
    // fetchScheduleBlocks();
    // fetchBlockSheetUsers();
    fetchStops();
  }, []);

  const openModal = (modal) => {
    if (modal === "route") {
      setIsRouteModalOpen(true);
    } else if (modal === "stop") {
      setIsStopModalOpen(true);
    }
  };

  const closeModal = (modal) => {
    if (modal === "route") {
      setIsRouteModalOpen(false);

      setNewRoute({ name: "", hexColor: "" });
    } else if (modal === "stop") {
      setIsStopModalOpen(false);
      setNewStop({
        name: "",

        attachment: null,
        routeIds: [],
      });
    } else if (modal === "stopEdit") {
      setIsStopEditModalOpen(false);
      setUpdatedStopName("");
      setUpdatedStopImage(null);
      setUpdatedStopRouteIds([]);
    } else if (modal === "stopOrder") {
      setIsStopOrderModalOpen(false);
    } else if (modal === "user") {
      setIsUserModalOpen(false);
      setNewUser({ email: "", password: "", isAdmin: false, name: "" });
    }
    setUpdateId(null);
  };

  const fetchUsers = async () => {
    try {
      const { data } = await axios.get("/auth/users");
      setUsers(data);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchBlockSheetUsers = async () => {
    try {
      const { data } = await axios.get("/auth/block/users");
      setBlockSheetUsers(data);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchStops = async () => {
    try {
      const { data } = await axios.get("/api/stops");
      setStops([...data]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteUser = async () => {
    try {
      await axios.delete(`/auth/delete-account/${userToDeleteId}`);
      showSuccessToast("User successfully deleted!");
      await fetchUsers();
      setUserToDeleteId(null);
    } catch (error) {
      console.error(error);
      showErrorToast(error.response.data.error);
    }
  };
  const handleResetPassword = async (userId) => {
    try {
      const { data } = await axios.post(`/auth/password/reset/${userId}`);

      setUsers((prevUsers) => {
        return prevUsers.map((user) =>
          user.id === userId
            ? {
                ...user,

                resetMandatory: data.user.resetMandatory,
              }
            : user
        );
      });

      showSuccessToast("Password reset successfully!");
      setUserToResetPasswordId(null);
    } catch (error) {
      console.error(error);
      showErrorToast(error.response?.data?.error || "Error resetting password");
    }
  };
  const updateUser = async (userId, data, file = null) => {
    try {
      const formData = new FormData();
      for (const key in data) {
        const value = data[key];

        formData.append(
          key,
          typeof value === "object" ? JSON.stringify(value) : value
        );
      }

      if (file) {
        formData.append("file", file);
      }

      await axios.put(`/auth/update-user/${userId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      showSuccessToast("User privileges updated!");
      fetchUsers();
    } catch (error) {
      console.error(error);
      showErrorToast(error.response.data.error);
    }
  };

  const inviteUser = async () => {
    try {
      await axios.post(
        `/auth/user/invite?email=${newUser.email}&isAdmin=${newUser.isAdmin}`,
        { name: newUser.name }
      );
      setNewUser({ email: "", password: "", isAdmin: false, name: "" });
      closeModal("user");

      showSuccessToast(
        "User invited! You must wait 5 minutes before sending another invitation."
      );
    } catch (error) {
      console.error(error);

      showErrorToast(error.response.data.error);
    }
  };
  const addRoute = async () => {
    try {
      await axios.post("/api/routes", {
        name: newRoute.name,
        color: newRoute.hexColor,
        bidirectional: newRoute.bidirectional,
      });

      showSuccessToast("New route added!");
      fetchRoutes();
      closeModal("route");
    } catch (error) {
      console.error(error);
      showErrorToast(error.response.data.error);
    }
  };

  const fetchRoutes = async () => {
    try {
      const { data } = await axios.get("/api/routes", {
        withCredentials: false,
      });
      setRoutes(data);
    } catch (error) {
      console.error(error);
    }
  };

  const updateRoute = async (routeId, newName, newColor, bidirectional) => {
    try {
      await axios.put(`/api/routes/update/${routeId}`, {
        name: updatedRouteName,
        color: updatedRouteColor,
        bidirectional: updatedBidirectional,
        abbreviation: updatedAbbreviation,
        inboundTimeToCompletion: updatedInboundTimeToCompletion,
        outboundTimeToCompletion: updatedOutboundTimeToCompletion,
      });

      showSuccessToast("Route updated!");
      fetchRoutes();
    } catch (error) {
      console.error(error);
      showErrorToast(error.response.data.error);
    }
  };

  const deleteRoute = async (routeId) => {
    try {
      await axios.delete(`/api/routes/${routeId}`);

      showSuccessToast("Route deleted!");
      fetchRoutes();
    } catch (error) {
      console.error(error);
      showErrorToast(error.response.data.error);
    }
  };

  const fetchScheduleBlocks = async () => {
    try {
      const { data } = await axios.get("/api/blocksheet");

      setScheduleBlocks(data.scheduleBlocks);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteBlock = async () => {
    try {
      setIsLoading(true);
      await axios.delete(`/api/blocksheet/${editBlock.id}`);
      showSuccessToast("Block successfully deleted!");
      setScheduleBlocks((prev) => prev.filter((sb) => sb.id !== editBlock.id));
      setEditBlock(null);
    } catch (error) {
      console.error(error);
      showErrorToast(error.response.data.error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateBlocksheet = async (block) => {
    try {
      setIsLoading(true);
      await axios.post(`/api/blocksheet`, { block });
      showSuccessToast("Block successfully created!");
      setScheduleBlocks((prev) => [block, ...prev]);
      return true;
    } catch (error) {
      console.error(error);
      showErrorToast(error.response.data.error);
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateBlocksheet = async () => {
    try {
      setIsLoading(true);
      await axios.put(`/api/blocksheet/${editBlock.id}`, {
        scheduleBlockData: editBlock,
      });

      setScheduleBlocks((prev) => {
        const updatedBlocks = prev.map((block) =>
          block.id === editBlock.id ? editBlock : block
        );
        return updatedBlocks;
      });
      setEditBlock(false);
      showSuccessToast("Block successfully edited!");
    } catch (error) {
      showErrorToast(error.response.data.error);
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const addStop = async () => {
    try {
      const formData = new FormData();
      if (newStop.attachment) {
        formData.append("file", newStop.attachment.file);
      }
      if (newStop.name.length) {
        formData.append("name", newStop.name);
        const { data } = await axios.post(`/api/stops`, formData);
        setStops((prev) => [...prev, { ...data }]);
        setNewStop({
          name: "",
          attachment: null,
        });
        showSuccessToast("Stop added successfully! ");
      } else {
        showErrorToast("Name is required!");
      }
    } catch (error) {
      console.error(error);

      showErrorToast(error.response.data.error);
      throw new Error(error);
    }
  };

  const updateStop = async () => {
    try {
      const formData = new FormData();
      if (updatedStopImage) {
        formData.append("file", updatedStopImage.file);
      }

      if (updatedStopName) {
        formData.append("name", updatedStopName);
      }
      if (updatedStopRouteIds) {
        formData.append("routeIds", JSON.stringify(updatedStopRouteIds));
      }
      const { data } = await axios.put(`/api/stops/${updateId}`, formData);
      fetchStops();
      fetchRoutes();
      showSuccessToast("Stop updated successfully! ");
    } catch (error) {
      console.error("Error updating stop:", error);
      throw error;
    }
  };
  const deleteStop = async (stopId) => {
    try {
      await axios.delete(`/api/stops/${stopId}`);
      setStops((prev) => prev.filter((stop) => stop.id !== stopId));
    } catch (error) {
      console.error("Error deleting stop:", error);
      throw error;
    }
  };
  const swapStopPosition = async (stopId1, order1, stopId2, order2) => {
    try {
      // Perform the API call to swap stop positions using stopId1 and order2
      const { data } = await axios.post(
        `/api/routes/${updateId}/stop/${stopId1}/${order2}/swap`
      );

      fetchRoutes();

      // Show success toast
      showSuccessToast("Stops swapped successfully!");
    } catch (error) {
      console.error("Error swapping stops:", error);
      showErrorToast(error.response?.data?.error || "");
      throw error;
    }
  };

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmailFormat(emailRegex.test(newUser.email));
  }, [newUser.email]);

  useEffect(() => {
    const isValidNameFormat = () => {
      const nameRegex = /^[a-zA-Z0-9\s]+$/;
      return newUser.name.trim() === "" || nameRegex.test(newUser.name);
    };
    setIsValidNameFormat(isValidNameFormat());
  }, [newUser.name]);

  useEffect(() => {
    const selectedRoute = routes.find((route) => route.id === updateId);

    const stopIds = selectedRoute ? selectedRoute.stopIds : [];

    setSelectedRouteStopsOrder(stopIds);
  }, [updateId, routes]);

  return (
    <Box>
      <Center>
        <Box
          p={4}
          maxWidth="1200px"
          width="100%">
          <Stack
            spacing={8}
            bg="compBg">
            <Flex
              direction={{ base: "column", md: "row" }}
              align={{ base: "center", md: "center" }}
              justify={{ base: "center", md: "center" }}
              mt={["6.8rem", "4.8rem"]}>
              <Button
                color="text"
                bg="compBg"
                variant={activeTab === "user" ? "solid" : "outline"}
                onClick={() => setActiveTab("user")}
                mb={{ base: 2, md: 0 }}
                mr={{ base: 0, md: 2 }}>
                Users
              </Button>{" "}
              <Button
                color="text"
                bg="compBg"
                variant={activeTab === "blocksheet" ? "solid" : "outline"}
                onClick={() => setActiveTab("blocksheet")}
                mb={{ base: 2, md: 0 }}
                mr={{ base: 0, md: 2 }}>
                Blocks
              </Button>
              {(user?.isAdmin ||
                user?.permissions?.find((perm) => perm.tabName === "dispatch")
                  ?.canAdmin) && (
                <Button
                  variant={activeTab === "routes" ? "solid" : "outline"}
                  onClick={() => setActiveTab("routes")}
                  mb={{ base: 2, md: 0 }}
                  mr={{ base: 0, md: 2 }}
                  color="text"
                  bg="compBg">
                  Routes
                </Button>
              )}{" "}
              <Button
                color="text"
                bg="compBg"
                variant={activeTab === "stop" ? "solid" : "outline"}
                onClick={() => setActiveTab("stop")}
                mb={{ base: 2, md: 0 }}
                mr={{ base: 0, md: 2 }}>
                Stops
              </Button>{" "}
              <Button
                color="text"
                bg="compBg"
                variant={activeTab === "announcement" ? "solid" : "outline"}
                onClick={() => setActiveTab("announcement")}
                mb={{ base: 2, md: 0 }}
                mr={{ base: 0, md: 2 }}>
                Announcements
              </Button>
            </Flex>{" "}
            {activeTab === "user" && (
              <UserManagement
                users={users}
                handleDeleteUser={handleDeleteUser}
                updateUser={updateUser}
                openModal={setIsUserModalOpen}
                setEditUser={setEditUser}
                editUser={editUser}
                setUserToDeleteId={setUserToDeleteId}
                userToDeleteId={userToDeleteId}
                userToResetPasswordId={userToResetPasswordId}
                setUserToResetPasswordId={setUserToResetPasswordId}
                handleResetPassword={handleResetPassword}
                fetchUsers={fetchUsers}
              />
            )}
            {activeTab === "blocksheet" && (
              <BlocksheetManagement
                scheduleBlocks={scheduleBlocks}
                handleDeleteBlock={handleDeleteBlock}
                openModal={setIsBlocksheetModalOpen}
                isOpen={isBlocksheetModalOpen}
                createBlock={handleCreateBlocksheet}
                updateBlocksheet={handleUpdateBlocksheet}
                setEditBlock={setEditBlock}
                editBlock={editBlock}
                setBlockToDeleteId={setBlockToDeleteId}
                blockToDeleteId={blockToDeleteId}
                setNewBlock={setNewBlock}
                newBlock={newBlock}
                users={blockSheetUsers}
                isLoading={isLoading}
                handleWeekChange={handleWeekChange}
                setCurrentWeek={setCurrentWeek}
                user={user}
                currentWeek={currentWeek}
              />
            )}{" "}
            {activeTab === "routes" && (
              <RouteManagement
                routes={routes}
                deleteRoute={deleteRoute}
                updateRoute={updateRoute}
                setUpdatedRouteName={setUpdatedRouteName}
                setUpdatedRouteColor={setUpdatedRouteColor}
                setUpdatedBidirectional={setUpdatedBidirectional}
                setUpdatedAbbreviation={setUpdatedAbbreviation}
                setUpdatedInboundTimeToCompletion={
                  setUpdatedInboundTimeToCompletion
                }
                setUpdatedOutboundTimeToCompletion={
                  setUpdatedOutboundTimeToCompletion
                }
                openNewRouteModal={() => openModal("route")}
                updatedRouteName={updatedRouteName}
                updatedRouteColor={updatedRouteColor}
                updatedBidirectional={updatedBidirectional}
                updatedAbbreviation={updatedAbbreviation}
                updatedInboundTimeToCompletion={updatedInboundTimeToCompletion}
                updatedOutboundTimeToCompletion={
                  updatedOutboundTimeToCompletion
                }
                updateId={updateId}
                setUpdateId={setUpdateId}
                setIsStopOrderModalOpen={setIsStopOrderModalOpen}
              />
            )}{" "}
            {activeTab === "stop" && (
              <StopManagement
                stops={stops}
                openNewStopModal={() => openModal("stop")}
                openEditStopModal={() => setIsStopEditModalOpen(true)}
                deleteStop={deleteStop}
                updateStop={updateStop}
                setUpdatedStopName={setUpdatedStopName}
                setUpdatedStopImage={setUpdatedStopImage}
                updateId={updateId}
                setUpdateId={setUpdateId}
                setUpdatedStopRouteIds={setUpdatedStopRouteIds}
              />
            )}{" "}
            {activeTab === "announcement" && <Announcements user={user} />}
          </Stack>
        </Box>
      </Center>

      <InviteUserModal
        isOpen={isUserModalOpen}
        onClose={() => closeModal("user")}
        handleInviteUser={inviteUser}
        newUser={newUser}
        setNewUser={setNewUser}
        isValidEmailFormat={isValidEmailFormat}
        isValidNameFormat={isValidNameFormat}
      />
      <RouteModal
        isOpen={isRouteModalOpen}
        onClose={() => closeModal("route")}
        addRoute={addRoute}
        newRoute={newRoute}
        setNewRoute={setNewRoute}
      />
      <StopModal
        isOpen={isStopModalOpen}
        onClose={() => closeModal("stop")}
        newStop={newStop}
        setNewStop={setNewStop}
        addStop={addStop}
      />
      <StopEditModal
        isOpen={isStopEditModalOpen}
        onClose={() => closeModal("stopEdit")}
        updateStop={updateStop}
        updatedStopName={updatedStopName}
        updatedStopImage={updatedStopImage}
        updatedStopRouteIds={updatedStopRouteIds}
        setUpdatedStopName={setUpdatedStopName}
        setUpdatedStopImage={setUpdatedStopImage}
        setUpdatedStopRouteIds={setUpdatedStopRouteIds}
        routes={routes}
        updateId={updateId}
      />
      <RouteStopOrderModal
        isOpen={isStopOrderModalOpen}
        onClose={() => closeModal("stopOrder")}
        routes={routes}
        handleSwapStopPosition={swapStopPosition}
        updateId={updateId}
      />
    </Box>
  );
}

export default Admin;
