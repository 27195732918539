import React, { useState } from "react";
import { Box, Button, Stack, Center, Flex } from "@chakra-ui/react";
import axios from "axios";

import { useSnackbar } from "../../context/SnackbarProvider";

import DispatchReportManagement from "../../components/DispatchReportManagement/DispatchReportManagement";
import DispatchForm from "../../components/DispatchReportManagement/DispatchForm";
import ResourceManagement from "../../components/ResourceManagement/ResourceManagment";
import RouteDashboard from "../../components/RouteManagement/RouteDashboard/RouteDashboard";

import InviteUserModal from "../../components/UserManagement/InviteUserModal";
import RecentAnnouncements from "../../components/Announcements/RecentAnnouncements";

function Dashboard({
  socket,

  user,
}) {
  const [newUser, setNewUser] = useState({
    email: "",
    password: "",
    userType: "dispatcher",
  });

  const [isinviteUserModalOpen, setIsinviteUserModalOpen] = useState(false);

  const [activeTab, setActiveTab] = useState("dispatchDashboard");

  const { showSuccessToast, showErrorToast } = useSnackbar();

  const inviteUser = async () => {
    try {
      await axios.post(
        `/auth/user/invite?email=${newUser.email}&userType=${newUser.userType}`
      );

      showSuccessToast(
        "User invited! You must wait 5 minutes before sending another invitation."
      );

      closeModal("inviteUser");
    } catch (error) {
      console.error(error);

      showErrorToast(error.response.data.error);
    }
  };
  const closeModal = (modal) => {
    if (modal === "inviteUser") {
      setIsinviteUserModalOpen(false);

      setNewUser({ email: "", password: "", userType: "dispatcher" });
    }
  };

  return (
    <Box>
      <Center>
        <Box
          p={4}
          maxWidth="1200px"
          width="100%">
          {" "}
          <Stack
            spacing={8}
            bg="compBg">
            {" "}
            <Box p={2}>
              <RecentAnnouncements page="dispatch" />
            </Box>
            <Flex
              direction={{ base: "column", md: "row" }}
              align={{ base: "center", md: "center" }}
              justify={{ base: "center", md: "center" }}
              mt={["6.8rem", "4.8rem"]}>
              <Button
                color="text"
                bg="compBg"
                variant={
                  activeTab === "dispatchDashboard" ? "solid" : "outline"
                }
                onClick={() => setActiveTab("dispatchDashboard")}
                mb={{ base: 2, md: 0 }}
                mr={{ base: 0, md: 2 }}>
                Route Dashboard
              </Button>

              <Button
                variant={activeTab === "dispatchReport" ? "solid" : "outline"}
                onClick={() => setActiveTab("dispatchReport")}
                mb={{ base: 2, md: 0 }}
                mr={{ base: 0, md: 2 }}
                color="text"
                bg="compBg">
                Dispatch Report
              </Button>
              {(user?.isAdmin ||
                user?.permissions?.find((perm) => perm.tabName === "dispatch")
                  ?.canAdmin) && (
                <Button
                  variant={activeTab === "submissions" ? "solid" : "outline"}
                  onClick={() => setActiveTab("submissions")}
                  mb={{ base: 2, md: 0 }}
                  mr={{ base: 0, md: 2 }}
                  color="text"
                  bg="compBg">
                  Submissions
                </Button>
              )}

              <Button
                variant={activeTab === "resources" ? "solid" : "outline"}
                onClick={() => setActiveTab("resources")}
                mb={{ base: 2, md: 0 }}
                mr={{ base: 0, md: 2 }}
                color="text"
                bg="compBg">
                Resources
              </Button>
            </Flex>
            {/* Dispatch Dashboard */}
            {activeTab === "dispatchDashboard" && (
              <Stack spacing={4}>
                <RouteDashboard
                  socket={socket}
                  user={user}
                />
              </Stack>
            )}
            {activeTab === "dispatchReport" && (
              <Stack spacing={4}>
                <DispatchForm />
              </Stack>
            )}
            {activeTab === "submissions" && (
              <DispatchReportManagement user={user} />
            )}{" "}
            {activeTab === "resources" && <ResourceManagement user={user} />}
          </Stack>
        </Box>
      </Center>

      <InviteUserModal
        isOpen={isinviteUserModalOpen}
        onClose={() => closeModal("inviteUser")}
        handleInviteUser={inviteUser}
        newUser={newUser}
        setNewUser={setNewUser}
      />
    </Box>
  );
}

export default Dashboard;
