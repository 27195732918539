import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
  Checkbox,
  Stack,
  Text, // Import Text component
} from "@chakra-ui/react";
import StopAttachment from "./StopAttachment";

function StopEditModal({
  isOpen,
  onClose,
  updateStop,
  updatedStopName,
  updatedStopImage,
  updatedStopRouteIds,
  setUpdatedStopName,
  setUpdatedStopImage,
  setUpdatedStopRouteIds,
  routes,
}) {
  const handleUpdateStop = async () => {
    try {
      await updateStop({
        name: updatedStopName,
        image: updatedStopImage,
        routeIds: updatedStopRouteIds,
      });
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const isNameValid = updatedStopName.length >= 2;

  const handleRouteCheckboxChange = (routeId) => {
    const isSelected = updatedStopRouteIds.includes(routeId);
    if (isSelected) {
      setUpdatedStopRouteIds(
        updatedStopRouteIds.filter((id) => id !== routeId)
      );
    } else {
      setUpdatedStopRouteIds([...updatedStopRouteIds, routeId]);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bg="compBg"
        color="text">
        <ModalHeader>Update Stop</ModalHeader>
        <ModalBody>
          <Input
            placeholder="Stop Name"
            value={updatedStopName}
            onChange={(e) => setUpdatedStopName(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <StopAttachment
            attachment={updatedStopImage}
            setAttachment={setUpdatedStopImage}
            onRemove={() => setUpdatedStopImage(null)}
          />
          <Text
            fontWeight="bold"
            mb={2}>
            Select Routes:
          </Text>{" "}
          <Stack
            mt={2}
            spacing={2}
            ml={2}
            maxH="300px"
            overflowY="auto">
            {routes.map((route) => (
              <Checkbox
                key={route.id}
                isChecked={updatedStopRouteIds.includes(route.id)}
                onChange={() => handleRouteCheckboxChange(route.id)}>
                {route.name}
              </Checkbox>
            ))}
          </Stack>
        </ModalBody>
        <ModalFooter sx={{ gap: 2 }}>
          <Button
            colorScheme="green"
            onClick={handleUpdateStop}
            isDisabled={!isNameValid}>
            Update Stop
          </Button>
          <Button
            variant="outline"
            onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default StopEditModal;
