import React, { useState } from "react";
import {
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  Input,
  Button,
  Text,
  Modal,
  ModalOverlay,
} from "@chakra-ui/react";

const UserInfoModal = ({
  isOpen,
  onClose,
  user,
  tierPoints,
  manualTierPoints,
  handleChangeTierPoints,
  handleSaveTierPoints,
}) => {
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };
  const [isEditingTier, setIsEditingTier] = useState(false);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        color="text"
        bg="compBg">
        <ModalHeader>{`${user?.firstName} ${user?.lastName}`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Email: {user?.email}</Text>
          <Text>Phone Number: {user?.telephone || "N/A"}</Text>
          <Text>Date of Hire: {formatDate(user?.dateOfHire)}</Text>
          <Text>Tier Points: {user?.tierPoints}</Text>
        </ModalBody>
        <ModalFooter>
          {!isEditingTier ? (
            <Button
              colorScheme="blue"
              size="md"
              onClick={() => setIsEditingTier(true)}>
              Manually Change Tier Points
            </Button>
          ) : (
            <Stack
              spacing={2}
              direction="row"
              justifyContent="center"
              w="100%">
              <Input
                type="number"
                placeholder="Enter tier points"
                value={manualTierPoints}
                onChange={(e) =>
                  handleChangeTierPoints(parseInt(e.target.value))
                }
              />
              <Button
                colorScheme="green"
                onClick={handleSaveTierPoints}>
                Save
              </Button>
              <Button
                variant="outline"
                onClick={onClose}>
                Cancel
              </Button>
            </Stack>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UserInfoModal;
