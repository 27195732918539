// RouteDetailsModal.js
import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Stack,
  Text,
} from "@chakra-ui/react";

function RouteDetailsModal({ isOpen, onClose, route }) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl">
      <ModalOverlay />
      <ModalContent
        bg="compBg"
        color="text">
        <ModalHeader fontSize="2xl">Route Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={6}>
            <Stack>
              <Text fontSize="lg">
                <strong>
                  {route?.bidirectional
                    ? "Inbound Time to Complete:"
                    : "Time to Complete (one-way):"}
                </strong>{" "}
                {route?.inboundTimeToCompletion
                  ? route.inboundTimeToCompletion
                  : "Unknown"}
              </Text>

              {route?.bidirectional && (
                <Text fontSize="lg">
                  <strong>Outbound Time to Complete:</strong>{" "}
                  {route?.outboundTimeToCompletion
                    ? route.outboundTimeToCompletion
                    : "Unknown"}
                </Text>
              )}
            </Stack>
            <Stack>
              <Text
                fontSize="lg"
                mb={2}>
                <strong>Stops:</strong>
              </Text>
              <Stack spacing={2}>
                {route?.stops?.map((stop, index) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    key={stop.id}
                    p={2}
                    borderRadius="md"
                    bg="compBg"
                    color="text">
                    <Text fontWeight="bold">{index + 1}.</Text>{" "}
                    <Text>{stop.name}</Text>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default RouteDetailsModal;
