// RouteAccordionItem.js
import React from "react";
import {
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Switch,
  HStack,
  Text,
  Button,
} from "@chakra-ui/react";
import RouteEditor from "./RouteEditor";
import { useSnackbar } from "../../../context/SnackbarProvider";

const RouteAccordionItem = ({
  route,
  handleCreateServiceUpdate,
  handleUpdateServiceUpdate,
  handleServiceUpdateDelete,
  handleEnableDisableRoute,
  onViewRouteDetails,
}) => {
  const { showErrorToast } = useSnackbar();

  const [loading, setLoading] = React.useState(false);

  const handleToggle = async () => {
    if (!loading) {
      try {
        setLoading(true);
        await handleEnableDisableRoute(route.id);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleUpdate = async (
    primaryServiceUpdate,
    secondaryServiceUpdate,
    routeType
  ) => {
    try {
      if (primaryServiceUpdate.type) {
        const existingServiceUpdate = route.serviceUpdates.find(
          (update) => update.routeType === routeType
        );

        if (!existingServiceUpdate) {
          await handleCreateServiceUpdate(
            route.id,
            primaryServiceUpdate,
            secondaryServiceUpdate,
            routeType
          );
        } else {
          await handleUpdateServiceUpdate(
            route.id,
            primaryServiceUpdate,
            secondaryServiceUpdate,
            routeType
          );
        }
      }
    } catch (error) {
      console.error(error);
      showErrorToast(error.response.data.error);
    }
  };

  return (
    <AccordionItem
      key={route.id}
      bg="compBg"
      color="text">
      <AccordionButton>
        <Box
          position="relative"
          width="30px"
          height="30px"
          borderRadius="50%"
          bg={route.color}
          mr={3}>
          <Text
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            color="white"
            fontWeight="bold"
            fontSize="12px">
            {route.abbreviation}
          </Text>
        </Box>
        <Box
          flex="1"
          textAlign="left"
          fontSize="22px">
          {route.name}
        </Box>
        <HStack
          spacing={3}
          mr={3}>
          <Switch
            colorScheme="green"
            size="lg"
            isChecked={route.enabled}
            disabled={loading}
            onChange={handleToggle}
          />
          <Button
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              onViewRouteDetails(route);
            }}>
            View {route.abbreviation ? route.abbreviation : "???"} Info
          </Button>{" "}
        </HStack>
        <AccordionIcon />
      </AccordionButton>

      <AccordionPanel pb={4}>
        {route.bidirectional ? (
          <>
            {" "}
            <RouteEditor
              route={route}
              onUpdate={handleUpdate}
              handleServiceUpdateDelete={handleServiceUpdateDelete}
              routeType={2}
            />
            <RouteEditor
              route={route}
              onUpdate={handleUpdate}
              handleServiceUpdateDelete={handleServiceUpdateDelete}
              routeType={1}
            />
          </>
        ) : (
          <RouteEditor
            route={route}
            onUpdate={handleUpdate}
            handleServiceUpdateDelete={handleServiceUpdateDelete}
            routeType={0}
          />
        )}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default RouteAccordionItem;
