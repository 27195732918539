import React from "react";
import { Box, Button, Text, Flex } from "@chakra-ui/react";

const AnnouncementItem = ({
  announcement,
  deleteAnnouncement,
  openModal,
  isEditing,
  setSelectedAnnouncementId,
}) => {
  const handleEdit = () => {
    setSelectedAnnouncementId(announcement.id);
    openModal(announcement.id);
  };

  return (
    <Box
      p={4}
      boxShadow="md"
      borderRadius="md"
      bg="compBg"
      color="text"
      borderBottom="1px solid">
      <Box>
        <Text fontWeight="bold">{announcement.title}</Text>
        <Text>{announcement.content}</Text>
        <Flex justifyContent="space-between">
          <Text
            fontSize="sm"
            color="gray.500"
            mt={2}>
            Posted by {announcement.user?.firstName}{" "}
            {announcement.user?.lastName}
          </Text>
          <Flex>
            <Button
              colorScheme="blue"
              onClick={handleEdit}
              ml={2}>
              Edit
            </Button>
            <Button
              colorScheme="red"
              onClick={() => deleteAnnouncement(announcement.id)}
              ml={2}>
              Delete
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default AnnouncementItem;
