import React, { useState } from "react";
import {
  Flex,
  Image,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  IconButton,
} from "@chakra-ui/react";

import { NavLink } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";

const Navbar = ({ logout, user, userPerms }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      right="0"
      zIndex="10"
      width="100%">
      <Flex
        align="center"
        direction={["row"]}
        justify={["flex-start", "space-between"]}
        bgColor="#2596be"
        p="0.8rem 0.8rem"
        width="100%"
        flex="1"
        position="relative">
        <Box
          display={{ base: "flex", md: "none" }}
          minW="60px"
          onClick={handleDrawerOpen}
          cursor="pointer"
          position="absolute">
          <IconButton
            icon={<HamburgerIcon />}
            alt="Menu"
            w="30px"
            h="30px"
            color="compBg"
            backgroundColor="transparent"
          />
        </Box>
        <Box margin="0 auto">
          <NavLink to="/">
            <Image
              src="/occtlogo.png"
              alt="Logo"
              maxH={["30px", "40px"]}
              display={{ base: "flex", md: "none", lg: "none" }}
            />
          </NavLink>
        </Box>
        <Box
          flex="0 1 100px"
          display={{ base: "none", sm: "none", md: "flex" }}
          alignItems="center">
          <NavLink to="/">
            <Image
              src="/occtlogo.png"
              alt="Logo"
              maxH={["30px", "40px"]}
            />
          </NavLink>
        </Box>
        {user?.id && (
          <Flex
            align="center"
            display={{ base: "none", md: "flex" }}
            justify={["space-evenly", "space-between"]}
            w="100%">
            <Flex
              flex={["1 0 60%", "1 0 80%"]}
              align="center"
              justify="center"
              bgColor="#2596be"
              direction={["row"]}
              h="50px"
              p={[2, 4]}
              color="text"
              gap={["0.4rem", "0.8rem"]}>
              <ChakraLink
                as={NavLink}
                to="/my-blocks"
                _activeLink={{ fontWeight: "semibold", color: "#000" }}>
                My Blocks
              </ChakraLink>
              <ChakraLink
                as={NavLink}
                to="/tradeboard"
                _activeLink={{ fontWeight: "semibold", color: "#000" }}>
                Tradeboard
              </ChakraLink>
              {(userPerms?.find((perm) => perm.tabName === "dispatch")
                ?.canView ||
                user?.isAdmin) && (
                <ChakraLink
                  as={NavLink}
                  to="/dispatch"
                  _activeLink={{
                    fontWeight: "semibold",
                    color: "#000",
                  }}
                  mr={[0, 2]}>
                  Dispatch
                </ChakraLink>
              )}
              {(userPerms?.find((perm) => perm.tabName === "training")
                ?.canView ||
                user?.isAdmin) && (
                <ChakraLink
                  to="/training"
                  as={NavLink}
                  _activeLink={{ fontWeight: "semibold", color: "#000" }}
                  mr={[0, 2]}
                  disabled>
                  Training
                </ChakraLink>
              )}
              <ChakraLink
                as={NavLink}
                to="/whiteboard"
                _activeLink={{ fontWeight: "semibold", color: "#000" }}
                mr={[0, 2]}
                disabled>
                Whiteboard
              </ChakraLink>
              {user.isAdmin && (
                <ChakraLink
                  as={NavLink}
                  to="/admin"
                  mr={[0, 2]}
                  _activeLink={{ fontWeight: "semibold", color: "#000" }}>
                  Admin
                </ChakraLink>
              )}
              {(userPerms?.find((perm) => perm.tabName === "website")
                ?.canView ||
                user?.isAdmin) && (
                <ChakraLink
                  as={NavLink}
                  to="/website"
                  _activeLink={{ fontWeight: "semibold", color: "#000" }}>
                  Website
                </ChakraLink>
              )}{" "}
              <ChakraLink
                as={NavLink}
                to="/lift"
                _activeLink={{ fontWeight: "semibold", color: "#000" }}
                mr={[0, 2]}
                disabled>
                Lift
              </ChakraLink>
            </Flex>

            <Menu>
              <MenuButton
                as={Avatar}
                size={["sm", "md"]}
                cursor="pointer"
                src={user.photoURL || ""}
                bg></MenuButton>
              <MenuList
                bg="compBg"
                color="text">
                <MenuItem
                  bg="compBg"
                  color="text">
                  <ChakraLink
                    as={NavLink}
                    color="text"
                    to="/settings"
                    _hover={{ bg: "hoverBg", color: "hoverText" }}>
                    User Settings
                  </ChakraLink>
                </MenuItem>
                <MenuItem
                  onClick={logout}
                  bg="compBg"
                  color="text"
                  _hover={{ bg: "hoverBg", color: "hoverText" }}>
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        )}
      </Flex>

      {/* Drawer for Mobile Menu */}

      <Drawer
        isOpen={isDrawerOpen}
        placement="right"
        onClose={handleDrawerClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader
            borderBottomWidth="1px"
            bgColor="#2596be"
            fontSize="2xl"
            fontWeight="bold">
            Menu
          </DrawerHeader>
          <DrawerBody bg="compBg">
            <Flex
              direction="column"
              alignItems="center"
              gap="12"
              mt="16px"
              bg="compBg">
              <ChakraLink
                as={NavLink}
                to="/my-blocks"
                onClick={handleDrawerClose}
                _activeLink={{ fontWeight: "semibold", color: "#000" }}
                bg="compBg"
                color="text">
                My Blocks
              </ChakraLink>
              <ChakraLink
                as={NavLink}
                to="/tradeboard"
                onClick={handleDrawerClose}
                _activeLink={{ fontWeight: "semibold", color: "#000" }}
                bg="compBg"
                color="text">
                Tradeboard
              </ChakraLink>
              {(userPerms?.find((perm) => perm.tabName === "dispatch")
                ?.canView ||
                user?.isAdmin) && (
                <ChakraLink
                  as={NavLink}
                  to="/dispatch"
                  onClick={handleDrawerClose}
                  _activeLink={{
                    fontWeight: "semibold",
                    color: "#000",
                  }}
                  mr={[0, 2]}
                  bg="compBg"
                  color="text">
                  Dispatch
                </ChakraLink>
              )}

              {(userPerms?.find((perm) => perm.tabName === "training")
                ?.canView ||
                user?.isAdmin) && (
                <ChakraLink
                  to="/training"
                  onClick={handleDrawerClose}
                  as={NavLink}
                  _activeLink={{ fontWeight: "semibold", color: "#000" }}
                  mr={[0, 2]}
                  disabled
                  bg="compBg"
                  color="text">
                  Training
                </ChakraLink>
              )}
              {(userPerms?.find((perm) => perm.tabName === "whiteboard")
                ?.canView ||
                user?.isAdmin) && (
                <ChakraLink
                  as={NavLink}
                  to="/whiteboard"
                  onClick={handleDrawerClose}
                  _activeLink={{ fontWeight: "semibold", color: "#000" }}
                  mr={[0, 2]}
                  disabled
                  bg="compBg"
                  color="text">
                  Whiteboard
                </ChakraLink>
              )}
              {user.isAdmin && (
                <ChakraLink
                  as={NavLink}
                  to="/admin"
                  onClick={handleDrawerClose}
                  mr={[0, 2]}
                  _activeLink={{ fontWeight: "semibold", color: "#000" }}
                  bg="compBg"
                  color="text">
                  Admin
                </ChakraLink>
              )}

              {(userPerms?.find((perm) => perm.tabName === "website")
                ?.canView ||
                user?.isAdmin) && (
                <ChakraLink
                  as={NavLink}
                  to="/website"
                  onClick={handleDrawerClose}
                  _activeLink={{ fontWeight: "semibold", color: "#000" }}
                  bg="compBg"
                  color="text">
                  Website
                </ChakraLink>
              )}

              <ChakraLink
                as={NavLink}
                to="/lift"
                onClick={handleDrawerClose}
                _activeLink={{ fontWeight: "semibold", color: "#000" }}
                bg="compBg"
                color="text">
                Lift
              </ChakraLink>
            </Flex>

            <Menu>
              <MenuButton
                as={Avatar}
                size={["sm", "md"]}
                cursor="pointer"
                src={user.photoURL || ""}
                bg=""></MenuButton>
              <MenuList
                bg="gray.100"
                color="gray.800">
                <MenuItem>
                  <ChakraLink
                    as={NavLink}
                    to="/settings"
                    color="gray.800"
                    _hover={{ bg: "gray.200", color: "gray.600" }}>
                    User Settings
                  </ChakraLink>
                </MenuItem>
                <MenuItem
                  onClick={logout}
                  _hover={{ bg: "gray.200", color: "gray.600" }}>
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Navbar;
