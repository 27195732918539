import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, Stack, Heading } from "@chakra-ui/react";
import AnnouncementItem from "./AnnouncementItem";
import AnnouncementModal from "./AnnouncementModal";
import { useSnackbar } from "../../context/SnackbarProvider";

const Announcements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAnnouncementId, setSelectedAnnouncementId] = useState(null);
  const { showSuccessToast, showErrorToast } = useSnackbar();

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  const fetchAnnouncements = async () => {
    try {
      const response = await axios.get("/api/announcements");
      setAnnouncements(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const addAnnouncement = async (announcement) => {
    try {
      await axios.post("/api/announcements", announcement);
      fetchAnnouncements();
      showSuccessToast("Announcement added successfully", "success");
    } catch (error) {
      console.error(error);
      showErrorToast("Failed to add announcement", "error");
    }
  };

  const deleteAnnouncement = async (id) => {
    try {
      await axios.delete(`/api/announcements/${id}`);
      fetchAnnouncements();
      showSuccessToast("Announcement deleted successfully", "success");
    } catch (error) {
      console.error(error);
      showErrorToast("Failed to delete announcement", "error");
    }
  };

  const openModal = (announcementId) => {
    setSelectedAnnouncementId(announcementId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAnnouncementId(null);
  };

  const editAnnouncement = async (
    id,
    title,
    content,
    homepage,
    training,
    dispatch
  ) => {
    try {
      await axios.put(`/api/announcements/${id}`, {
        title,
        content,
        homepage,
        training,
        dispatch,
      });
      fetchAnnouncements();
      showSuccessToast("Announcement updated successfully", "success");
    } catch (error) {
      console.error(error);
      showErrorToast("Failed to update announcement", "error");
    }
  };

  console.log(selectedAnnouncementId);
  return (
    <Box color="text">
      <Stack
        spacing={[4, 8]}
        p={[2, 4]}
        boxShadow="md"
        borderRadius="md"
        bg="compBg"
        color="text">
        <Heading
          as="h3"
          size="md"
          mb={4}>
          Manage Announcements
        </Heading>
        <Button
          colorScheme="blue"
          onClick={() => openModal(null)}>
          New Announcement
        </Button>
        <Box>
          <ul style={{ listStyle: "none", padding: 0 }}>
            {announcements.map((announcement) => (
              <li key={announcement.id}>
                <AnnouncementItem
                  announcement={announcement}
                  deleteAnnouncement={deleteAnnouncement}
                  openModal={openModal}
                  isEditing={announcement.id === selectedAnnouncementId}
                  setSelectedAnnouncementId={setSelectedAnnouncementId}
                  editAnnouncement={editAnnouncement}
                />
              </li>
            ))}
          </ul>
        </Box>
      </Stack>
      <AnnouncementModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={(data) => {
          if (selectedAnnouncementId) {
            editAnnouncement(
              selectedAnnouncementId,
              data.title,
              data.content,
              data.homepage,
              data.training,
              data.dispatch
            );
          } else {
            addAnnouncement(data).then(closeModal);
          }
        }}
        announcement={
          selectedAnnouncementId
            ? announcements.find((a) => a.id === selectedAnnouncementId)
            : null
        }
      />
    </Box>
  );
};

export default Announcements;
