import React, { useState } from "react";
import {
  Box,
  Select,
  Textarea,
  Button,
  Input,
  Divider,
  Text,
  Flex,
} from "@chakra-ui/react";
const maxCharacters = 3000;

function RouteEditor({
  route,
  onUpdate,
  handleServiceUpdateDelete,
  routeType,
}) {
  const primaryServiceUpdate = route?.serviceUpdates.find(
    (update) => update.routeType === routeType && !update.isSecondUpdate
  );

  const [primaryStatus, setPrimaryStatus] = useState(
    primaryServiceUpdate?.type || "On or Close"
  );
  const [primaryServiceUpdateText, setPrimaryServiceUpdateText] = useState(
    primaryServiceUpdate?.serviceUpdateText || ""
  );
  const [primaryExpirationDate, setPrimaryExpirationDate] = useState(
    primaryServiceUpdate?.expiration
      ? new Date(primaryServiceUpdate.expiration).toISOString().slice(0, 16)
      : ""
  );

  const secondaryServiceUpdate = route?.serviceUpdates.find(
    (update) => update.routeType === routeType && update.isSecondUpdate
  );

  const [secondaryServiceUpdateText, setSecondaryServiceUpdateText] = useState(
    secondaryServiceUpdate?.serviceUpdateText || ""
  );
  const [isSecondUpdate, setIsSecondUpdate] = useState(
    !!secondaryServiceUpdate
  );

  const handleAddServiceUpdate = () => {
    setIsSecondUpdate(true);
  };

  const handlePrimaryServiceUpdateStatusChange = (e) => {
    if (
      ["Delays", "On or Close", "No Service", "Cancelled"].includes(
        e.target.value
      )
    ) {
      setPrimaryServiceUpdateText("");
      setPrimaryExpirationDate("");
      setIsSecondUpdate(false);
      setSecondaryServiceUpdateText("");
    }
    setPrimaryStatus(e.target.value);
  };

  const handleDeleteSecondUpdate = async () => {
    if (route?.serviceUpdates.length === 2) {
      await handleServiceUpdateDelete(
        route.id,
        route.serviceUpdates[1].id,
        routeType
      );
    }
    setIsSecondUpdate(false);
    setSecondaryServiceUpdateText("");
  };

  const handleUpdateRoute = async () => {
    const primaryUpdate = {
      type: primaryStatus,
      serviceUpdateText: primaryServiceUpdateText,
      expiration: primaryExpirationDate.length ? primaryExpirationDate : null,
    };
    const secondaryUpdate = {
      type: isSecondUpdate ? "Delays" : "",
      serviceUpdateText: secondaryServiceUpdateText,
      expiration: null,
    };
    await onUpdate(primaryUpdate, secondaryUpdate, routeType);
  };

  const handlePrimaryServiceUpdateTextChange = (e) => {
    const text = e.target.value;
    if (text.length <= maxCharacters) {
      setPrimaryServiceUpdateText(text);
    }
  };

  const handleSecondaryServiceUpdateTextChange = (e) => {
    const text = e.target.value;
    if (text.length <= maxCharacters) {
      setSecondaryServiceUpdateText(text);
    }
  };

  return (
    <Box p={4}>
      {routeType === 2 && (
        <>
          <Divider />
          <Text
            mt={2}
            mb={4}
            fontWeight={"bold"}>
            {route.abbreviation} Outbound
          </Text>
        </>
      )}
      {routeType === 1 && (
        <Text
          mt={2}
          mb={4}
          fontWeight={"bold"}>
          {route.abbreviation} Inbound
        </Text>
      )}
      <Box>
        <Select
          mb={4}
          value={primaryStatus}
          onChange={handlePrimaryServiceUpdateStatusChange}>
          <option value="On or Close">On or Close</option>
          <option value="Delays">Delays</option>
          <option value="No Service">No Service</option>
          <option value="Planned Detour">Planned Detour</option>
          <option value="Cancelled">Cancelled</option>
        </Select>

        {(primaryStatus === "Planned Detour" ||
          primaryStatus === "Delays" ||
          primaryStatus === "Cancelled") && (
          <Box
            display="flex"
            flexDirection="column">
            <Textarea
              mb={4}
              placeholder="Service Update Text"
              value={primaryServiceUpdateText}
              onChange={handlePrimaryServiceUpdateTextChange}
            />
            <Text
              mb={2}
              ml="auto">
              {`${primaryServiceUpdateText.length}/${maxCharacters} characters`}
            </Text>
          </Box>
        )}

        {primaryStatus === "Planned Detour" && (
          <Input
            mb={4}
            type="datetime-local"
            value={primaryExpirationDate}
            onChange={(e) => setPrimaryExpirationDate(e.target.value)}
          />
        )}

        <Divider />

        {primaryStatus === "Planned Detour" && !isSecondUpdate && (
          <Button
            colorScheme="blue"
            size="sm"
            onClick={handleAddServiceUpdate}>
            {primaryStatus === "Delays" ? "Add Planned Detour" : "Add Delays"}
          </Button>
        )}

        {isSecondUpdate && (
          <>
            <Text
              mt={2}
              mb={4}>
              Delays
            </Text>

            {primaryStatus === "Planned Detour" && (
              <Box
                display="flex"
                flexDirection="column">
                <Textarea
                  mb={4}
                  placeholder="Service Update Text"
                  value={secondaryServiceUpdateText}
                  onChange={handleSecondaryServiceUpdateTextChange}
                />
                <Text mb={2}>
                  {`${secondaryServiceUpdateText.length}/${maxCharacters} characters`}
                </Text>
              </Box>
            )}
          </>
        )}
        {isSecondUpdate && (
          <Flex
            w="100%"
            justifyContent="end">
            <Button
              colorScheme="red"
              size="sm"
              onClick={handleDeleteSecondUpdate}>
              Delete
            </Button>
          </Flex>
        )}
      </Box>

      <Button
        mt={4}
        colorScheme="blue"
        onClick={handleUpdateRoute}>
        Update Route
      </Button>
    </Box>
  );
}

export default RouteEditor;
