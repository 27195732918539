import React from "react";
import { Box, Button, Stack, Heading } from "@chakra-ui/react";
import StopUpdateCard from "./StopUpdateCard";

const StopManagement = ({
  stops,
  deleteStop,
  updateStop,
  setUpdatedStopName,
  setUpdatedStopImage,
  setUpdatedStopRouteIds,
  openNewStopModal,
  openEditStopModal,
  updatedStopName,
  updatedStopImage,
  updateId,
  setUpdateId,
}) => {
  return (
    <Box color="text">
      <Stack
        spacing={[4, 8]}
        p={[2, 4]}
        boxShadow="md"
        borderRadius="md"
        bg="compBg"
        color="text">
        <Heading
          as="h3"
          size="md"
          mb={4}>
          Manage Stops
        </Heading>
        <Button
          colorScheme="blue"
          onClick={() => openNewStopModal()}>
          New Stop
        </Button>

        <Box>
          <ul style={{ listStyle: "none", padding: 0 }}>
            {stops.map((stop) => (
              <li key={stop.id}>
                <StopUpdateCard
                  stop={stop}
                  deleteStop={deleteStop}
                  updateStop={updateStop}
                  setUpdatedStopName={setUpdatedStopName}
                  setUpdatedStopImage={setUpdatedStopImage}
                  setUpdatedStopRouteIds={setUpdatedStopRouteIds}
                  setUpdateId={setUpdateId}
                  updateId={updateId}
                  openEditStopModal={openEditStopModal}
                />
              </li>
            ))}
          </ul>
        </Box>
      </Stack>
    </Box>
  );
};

export default StopManagement;
