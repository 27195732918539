import React, { useState } from "react";
import {
  Box,
  Button,
  Text,
  Stack,
  Heading,
  Select,
  Input,
  Center,
  HStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import axios from "axios";
import { useSnackbar } from "../../context/SnackbarProvider";
const Settings = ({
  changePasswordWithToken,
  user,
  setAndStoreThemeMode,
  themeMode,
  onAdminChangeName,
  setUser,
}) => {
  const [password, setPassword] = useState("");
  const [editing, setEditing] = useState(false);
  const [firstName, setFirstName] = useState(user?.firstName || "");
  const [lastName, setLastName] = useState(user?.lastName || "");
  const [email, setEmail] = useState(user?.email || "");
  const [telephone, setTelephone] = useState(user?.telephone || "");
  const [nameError, setNameError] = useState("");
  const [telephoneError, setTelephoneError] = useState("");

  const { showSuccessToast, showErrorToast } = useSnackbar();
  const handleChangePassword = async () => {
    try {
      await changePasswordWithToken(password);
      setPassword("");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveChanges = async () => {
    try {
      if (firstName.trim().length < 2) {
        setNameError("First name must be at least 2 characters long.");
        return;
      }

      if (lastName.trim().length < 2) {
        setNameError("Last name must be at least 2 characters long.");
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email.trim())) {
        setNameError("Invalid email format.");
        return;
      }

      const telephoneRegex = /^\d{10}$/;
      if (!telephoneRegex.test(telephone.trim())) {
        setTelephoneError("Invalid telephone number format (10 digits only).");
        return;
      }

      const userData = {
        firstName,
        lastName,
        email,
        telephone,
      };

      await updateUser(user.id, userData);

      setEditing(false);
    } catch (error) {
      console.error(error);
      showErrorToast(error.response.data.error);
    }
  };

  const updateUser = async (userId, data, file = null) => {
    try {
      const formData = new FormData();
      for (const key in data) {
        const value = data[key];

        formData.append(
          key,
          typeof value === "object" ? JSON.stringify(value) : value
        );
      }

      if (file) {
        formData.append("file", file);
      }

      const { data: userData } = await axios.put(
        `/auth/update-user/${userId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setUser({ ...userData.data });
      showSuccessToast("User information updated!");
    } catch (error) {
      console.error(error);
      showErrorToast(error.response.data.error);
    }
  };
  const handleThemeChange = (selectedTheme) => {
    setAndStoreThemeMode(selectedTheme);
  };

  const isPasswordValid = password.length >= 8;

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancelEdit = () => {
    setFirstName(user?.firstName || "");
    setLastName(user?.lastName || "");
    setEmail(user?.email || "");
    setTelephone(user?.telephone || "");
    setEditing(false);
    setNameError("");
    setTelephoneError("");
  };

  return (
    <Box>
      <Center>
        <Box
          mt="6.4rem"
          p={4}
          maxWidth="1200px"
          width="100%">
          <Tabs
            isFitted
            color="white">
            <TabList mb="4">
              <Tab>General</Tab>
              <Tab>Password</Tab>
              <Tab>Display</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Stack
                  spacing={[4, 8]}
                  p={[4, 8]}
                  boxShadow="md"
                  borderRadius="md"
                  bg="compBg"
                  color="text">
                  <Center>
                    <Box
                      mt="1.6rem"
                      p={4}
                      maxWidth="1200px"
                      width="100%">
                      <Heading
                        as="h3"
                        size="md"
                        mb={4}>
                        General Settings
                      </Heading>{" "}
                      <Stack
                        mt={["0.4rem"]}
                        mx="auto"
                        spacing={[4, 8]}
                        p={[4, 8]}
                        bg="compBg"
                        color="text"
                        align="center"
                        maxWidth="400px">
                        <Box w="100%">
                          <Text
                            fontWeight="bold"
                            mb={2}>
                            Name
                          </Text>
                          <Input
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder="First Name"
                            isDisabled={!editing}
                          />
                          <Input
                            mt={2}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder="Last Name"
                            isDisabled={!editing}
                          />
                          {nameError && (
                            <Text
                              color="red"
                              fontSize="sm"
                              mt={1}>
                              {nameError}
                            </Text>
                          )}
                        </Box>

                        <Box w="100%">
                          <Text
                            fontWeight="bold"
                            mb={2}>
                            Email
                          </Text>
                          <Input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            isDisabled={!editing}
                          />
                        </Box>

                        <Box w="100%">
                          <Text
                            fontWeight="bold"
                            mb={2}>
                            Telephone
                          </Text>
                          <Input
                            value={telephone}
                            onChange={(e) => setTelephone(e.target.value)}
                            placeholder="Telephone Number"
                            isDisabled={!editing}
                          />
                          {telephoneError && (
                            <Text
                              color="red"
                              fontSize="sm"
                              mt={1}>
                              {telephoneError}
                            </Text>
                          )}
                        </Box>
                        <Box ml="auto">
                          {editing ? (
                            <HStack>
                              <Button
                                mt={4}
                                colorScheme="green"
                                onClick={handleSaveChanges}>
                                Save Changes
                              </Button>
                              <Button
                                mt={4}
                                color="text"
                                bg="compBg"
                                variant="outline"
                                onClick={handleCancelEdit}>
                                Cancel
                              </Button>
                            </HStack>
                          ) : (
                            <Button
                              mt={4}
                              colorScheme="blue"
                              onClick={handleEdit}>
                              Edit
                            </Button>
                          )}
                        </Box>
                      </Stack>
                    </Box>
                  </Center>
                </Stack>
              </TabPanel>
              <TabPanel>
                <Stack
                  spacing={[4, 8]}
                  p={[4, 8]}
                  boxShadow="md"
                  borderRadius="md"
                  bg="compBg"
                  color="text">
                  <Heading
                    as="h3"
                    size="md">
                    Password Settings
                  </Heading>
                  <Center>
                    <Box
                      mt="1.6rem"
                      p={4}
                      maxWidth="1200px"
                      width="100%">
                      <Stack
                        mt={["0.4rem"]}
                        mx="auto"
                        spacing={[4, 8]}
                        p={[4, 8]}
                        bg="compBg"
                        color="text"
                        align="center"
                        maxWidth="400px">
                        <Box w="100%">
                          <Text
                            fontWeight="bold"
                            mb={2}>
                            Change Password
                          </Text>
                          <Input
                            type="password"
                            placeholder="Enter new password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          {!isPasswordValid && (
                            <Text
                              color="red"
                              fontSize="sm"
                              mt={1}>
                              Password must be at least 8 characters long.
                            </Text>
                          )}
                          <Button
                            mt={2}
                            colorScheme="blue"
                            onClick={handleChangePassword}
                            isDisabled={!isPasswordValid}>
                            Change Password
                          </Button>
                        </Box>
                      </Stack>
                    </Box>
                  </Center>
                </Stack>
              </TabPanel>
              <TabPanel>
                <Stack
                  spacing={[4, 8]}
                  p={[4, 8]}
                  boxShadow="md"
                  borderRadius="md"
                  bg="compBg"
                  color="text">
                  <Heading
                    as="h3"
                    size="md"
                    mb={4}>
                    Display Settings
                  </Heading>
                  <Center>
                    <Box
                      p={4}
                      maxWidth="1200px"
                      width="100%">
                      <Stack
                        mt={["0.4rem"]}
                        mx="auto"
                        spacing={[4, 8]}
                        p={[4, 8]}
                        bg="compBg"
                        color="text"
                        align="center"
                        maxWidth="400px">
                        <Box w="100%">
                          <Text
                            fontWeight="bold"
                            mb={2}>
                            Theme
                          </Text>

                          <Select
                            w="100%"
                            value={themeMode}
                            onChange={(e) => handleThemeChange(e.target.value)}>
                            <option value="auto">Auto</option>
                            <option value="light">Light</option>
                            <option value="dark">Dark</option>
                          </Select>
                          <Text>Choose your preferred theme</Text>
                        </Box>
                      </Stack>
                    </Box>
                  </Center>
                </Stack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Center>
    </Box>
  );
};

export default Settings;
